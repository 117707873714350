<template>
  <div class="additionalSettlementPageBox page-info-content">
    <Details-page
      :details-title="'基本信息'"
      :detail-obj="detailObj"
      :details-item-arr="detailsItemArr"
    />
    <!-- 结算商品 -->
    <h3 class="page-subtitle-shade settlementTitle">
      &nbsp;结算商品
      <span>本次结算最大输入值=入库量-已结算量；最小输入值=自上次结算起本次结算止提货出库数量</span>
    </h3>
    <div class="table">
      <el-table
        :data="listData"
        border
        :max-height="482"
      >
        <el-table-column width="55" fixed="left">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.isSelected" :disabled="Number(scope.row.currSettleWeight)===0||scope.row.isDisabled" @change="changeSelect(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="品名" width="180" />
        <el-table-column prop="goodsMaterials" label="材质" width="180" />
        <el-table-column prop="goodsSpecs" label="规格" width="180" />
        <el-table-column prop="goodsProduct" label="产地" width="180" />
        <el-table-column prop="goodsWeights" label="件重(吨)" width="180" />
        <el-table-column
          prop="contractValue"
          label="合同单价"
          width="178"
        >
          <template slot-scope="scope">
            <!-- <p>{{ scope.row.purchaseWeight || 0 }}吨</p> -->
            <p>{{ scope.row.purchasePrice || 0 }}元/吨</p>
          </template>
        </el-table-column>
        <el-table-column prop="invWeight" label="入库量(吨)" width="180" />
        <el-table-column prop="deliveryWeight" label="已提量(吨)" width="180" />
        <el-table-column prop="settleWeight" label="已结算量(吨)" width="180" />
        <el-table-column prop="unSettleAndDeliveryWeight" label="已提未结量(吨)" width="180" />
        <el-table-column label="本次结算量(吨)" width="180" fixed="right">
          <template slot-scope="scope">
            <el-input-number
              v-if="Number(scope.row.currSettleWeight)>0"
              v-model="scope.row.amountSettlement"
              :precision="3"
              :controls="false"
              :max="Number(scope.row.currSettleWeight)||0"
              :min="Number(scope.row.unSettleAndDeliveryWeight)>Number(scope.row.currSettleWeight)?Number(scope.row.currSettleWeight):Number(scope.row.unSettleAndDeliveryWeight)"
              :class="[!scope.row.amountSettlement&&scope.row.isSelected?'intervalValue':'']"
              :placeholder="`最大可输入${Number(scope.row.currSettleWeight)||0}`"
              :disabled="!scope.row.isSelected"
              @change="changeInput"
            />
            <!-- 判断最小值  如果是小于最大值就取最小值  反之取自己 -->
          </template>
        </el-table-column>
        <el-table-column
          prop="upstreamSettlementPrice"
          label="上游结算单价(元/吨)"
          width="190"
          fixed="right"
        >
          <template slot="header">
            上游结算单价(元/吨)
            <el-tooltip class="item" effect="dark" content="仅锁量类型可以进行录价操作" placement="top">
              <i class="el-icon-warning" />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <el-input-number
              v-if="Number(scope.row.currSettleWeight)>0&&detailObj.transType===1"
              v-model="scope.row.upstreamSettlementPrice"
              :precision="2"
              :controls="false"
              :class="[!scope.row.upstreamSettlementPrice&&scope.row.isSelected?'intervalValue':'']"
              placeholder="上游结算单价"
              :disabled="!scope.row.isSelected"
              :max="9999.99"
              :min="0"
              @change="statisticsComputation"
            />
            <span v-if="detailObj.transType===2">{{ scope.row.purchasePrice }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="subtotalSettlement"
          label="结算小计(元)"
          width="120"
          fixed="right"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.subtotalSettlement||0 }}</span>
            <!-- 判断最小值  如果是小于最大值就取最小值  反之取自己 -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 确认信息 -->
    <h3 class="page-subtitle-shade settlementTitle">
      &nbsp;结算确认
      <span>按比率计息时，代采天数不满7天按7天计息</span>
    </h3>
    <el-descriptions
      class="confirmationSettlement"
      :column="2"
      :colon="false"
      label-class-name="labelClassName"
      size="medium"
    >
      <el-descriptions-item>
        <template slot="label">
          结算总数量(吨)
        </template>
        <el-input v-model="detailObj.settleTotalWeight" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          结算总货款(元)
        </template>
        <el-input v-model="detailObj.settleAmount" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          已支付货款(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="结算周期内提货出库数量对应货款"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.paymentPaid" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          货款差额(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="货款差额=结算总货款-已支付货款"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.differencePayment" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          本次待收代采费(元)
        </template>
        <el-input v-model="detailObj.unpaidAgentCost" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          已收代采费(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="结算周期内提货时收取的代采费"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.agentCost" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          可抵扣保证金(元)
        </template>
        <el-input v-model="detailObj.deductibleDepositAmount" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item v-if="detailObj.isOverdue === '1'">
        <template slot="label">
          待收逾期费(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="待收逾期费（元）=逾期费用-已支付"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.unpaidOverdueCost" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item v-if="detailObj.isOverdue === '1'">
        <template slot="label">
          已收逾期费(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="已收逾期费=结算周期内提货时收取的逾期费"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.overdueCost" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          结算差额(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="结算差额=货款差额+本次待收代采费+待收逾期费-可抵扣保证金"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.settleDiff" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          结算总金额(元)
          <el-tooltip
            class="item"
            effect="dark"
            content="结算总金额=结算总货款+待收代采费+已收代采费+待收逾期费+已收逾期费"
            placement="top"
          >
            <i class="icon el-icon-warning" />
          </el-tooltip>
        </template>
        <el-input v-model="detailObj.totalSettlementAmount" disabled placeholder="请输入" />
      </el-descriptions-item>
      <el-descriptions-item />
    </el-descriptions>
    <!-- 应收差额 -->
    <h3 v-if="Number(detailObj.settleDiff) !== 0" class="differential">
      应{{ Number(detailObj.settleDiff)>=0?'收':'付' }}差额{{ Math.abs(detailObj.settleDiff)||0 }}元
    </h3>
    <div class="cancelButton">
      <!-- <el-button size="small" plain class="widen-button" @click="$router.go(-1)">
        返回
      </el-button> -->
      <el-button type="primary" size="small" class="widen-button" :loading="$store.state.loading" @click="submit">
        确定结算
      </el-button>
    </div>
    <CushionCapitalRecord :id="detailObj.contractId" ref="cushionCapitalRecord" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { agentSettleInfoQueryByDetail, agentSettleCalAgentAmount } from '@/http/agentApi/financialManage'
import { agentSettlePriceRecording } from '@/http/agentApi/salesContractManagement'
import CushionCapitalRecord from '@/components/CushionCapitalRecord.vue'
import { roundUpToInteger } from '@/unit/index'
export default {
  components: { DetailsPage, CushionCapitalRecord },
  data() {
    return {
      detailObj: {},
      listData: [],
      isTip: true,
      isStatisticsComputation: false, // 判断是否计算
      detailsItemArr: [
        { label: '经销商', value: 'custCmpName' },
        { label: '代采商', value: 'fundsCmpName' },
        { label: '合作企业', value: 'supplierCmpName' },
        { label: '结算单号', value: 'settleNo' },
        {
          label: '结算状态',
          value: 'settleStatus',
          child: this.$store.getters.getDictionaryItem('settleStatus')
        },
        {
          label: '交易类型',
          value: 'transType',
          child: [{ dictName: '锁量', dictId: 1 }, { dictName: '锁价', dictId: 2 }]
        },
        {
          label: '合同编号',
          value: 'custContractNo',
          path: '/salesContractManagement/salesContractDetails',
          queryObj: { rowId: '' }
        },
        {
          label: '提货记录',
          path: '/pickUpGoods/index',
          queryObj: { contractNo: '' },
          leftWords: '查看记录'
        },
        {
          label: '垫资记录',
          leftWords: '查看记录',
          operator: this.checkAdvanceRecord
        }
      ]
    }
  },
  watch: {
    isStatisticsComputation: {
      handler(newVal) {
        // 如果参数变为true  就去计算  如果没有的话就不变了
        if (newVal) {
          this.statisticsComputation()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    if (this.$route.query.id) {
      agentSettleInfoQueryByDetail(this.$route.query.id, (res) => {
        this.detailObj = { ...res.data }
        // 把参数归0
        this.detailObj.differencePayment = 0
        this.detailObj.totalSettlementAmount = 0
        this.detailObj.settleAmount = 0
        this.detailObj.settleTotalWeight = 0
        this.detailObj.unpaidAgentCost = 0
        this.detailObj.deductibleDepositAmount = 0
        // 给要跳转的提货记录进行赋值
        this.detailsItemArr.forEach((item) => {
          if (item.path) {
            item.queryObj.rowId = this.detailObj.contractId
            item.queryObj.contractNo = this.detailObj.custContractNo
          }
        })
        if (this.detailObj.settleGoodsRespList) {
          this.listData = [...this.detailObj.settleGoodsRespList]
          let flag = false
          this.listData.forEach(item => {
            // 如果是最小值大于0的  就默认选上  而且不可以不选了
            if (Number(item.unSettleAndDeliveryWeight) > 0) {
              item.isSelected = true
              item.isDisabled = true
              flag = true
              item.amountSettlement = Number(item.unSettleAndDeliveryWeight) > Number(item.currSettleWeight) ? Number(item.currSettleWeight) : Number(item.unSettleAndDeliveryWeight)
            }
          })
          if (flag) this.changeInput()
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isTip) this.$message('您可前往结算单管理完成结算操作')
    next()
  },
  methods: {
    // 提交数据
    submit() {
      const goodsRecordReqList = []
      let flag = false
      this.listData.forEach(item => {
        // 如果是锁量的  就把两个都填了的放进去   如果是锁价的  只要把已经选了的  而且填过的放进去了
        if (this.detailObj.transType === 2) {
          // 都有的数据菜加进去
          if (item.isSelected && item.amountSettlement) {
            goodsRecordReqList.push({
              currSettleWeight: item.amountSettlement,
              custContractGoodsId: item.contractGoodsId,
              settlePrice: item.purchasePrice
            })
          }
          // 如果勾选了  没填也要判断
          if (item.isSelected && !item.amountSettlement) {
            flag = true
          }
          return
        }
        // 这个是锁量的/
        if (item.isSelected && item.amountSettlement && item.upstreamSettlementPrice) {
          goodsRecordReqList.push({
            currSettleWeight: item.amountSettlement,
            custContractGoodsId: item.contractGoodsId,
            settlePrice: item.upstreamSettlementPrice
          })
        }
        // 如果勾选了  没去填写 也要进行提示
        if (item.isSelected && (!item.amountSettlement || !item.upstreamSettlementPrice)) {
          flag = true
        }
      })
      if (flag) {
        this.$message.error('请完善结算商品信息')
        return
      }
      if (goodsRecordReqList.length === 0) {
        this.$message.error('请先勾选您要结算的商品')
        return
      }
      const submitObj = {
        contractStatus: this.detailObj.custContractStatus || '',
        custContractId: this.detailObj.contractId,
        transType: this.detailObj.transType,
        settleId: this.detailObj.id,
        goodsRecordReqList: [...goodsRecordReqList]
      }
      agentSettlePriceRecording(submitObj, () => {
        this.isTip = false
        this.$message.success('成功')
        this.$router.push({
          path: '/financialManage/agentSettlementManage'
        })
      })
    },
    // 改变数量的时候计算
    changeInput() {
      const goodsDetailList = []
      this.listData.forEach(item => {
        // 如果是锁价就用原来的价钱
        if (item.isSelected && Number(item.amountSettlement) >= 0) {
          goodsDetailList.push({
            settleGooodsId: item.settleGoodsId || '',
            currSettleWeight: item.amountSettlement,
            deliveryWeight: item.unSettleAndDeliveryWeight
          })
        }
      })
      // 如果填的数量有的话  就去请求接口
      if (goodsDetailList.length > 0) {
        agentSettleCalAgentAmount(goodsDetailList, res => {
          this.detailObj.unpaidAgentCost = Number(res.data.agentCost) || 0
          this.detailObj.deductibleDepositAmount = Number(res.data.deductibleDepositAmount) || 0
          this.detailObj.unpaidOverdueCost = Number(res.data.unpaidOverdueCost) || 0
          this.isStatisticsComputation = true
        }, () => {
          this.detailObj.unpaidAgentCost = 0
          this.detailObj.deductibleDepositAmount = 0
          this.detailObj.unpaidOverdueCost = 0
          this.isStatisticsComputation = false
        })
        return
      }
      this.detailObj.unpaidAgentCost = 0
      this.detailObj.deductibleDepositAmount = 0
      this.isStatisticsComputation = false
    },
    // 计算统计
    statisticsComputation() {
      let settleTotalWeight = 0; let settleAmount = 0
      this.listData.forEach(item => {
        // 如果是锁价就用原来的价钱
        if (item.isSelected && Number(item.amountSettlement) >= 0) {
          settleTotalWeight += (Number(item.amountSettlement) || 0)
          const ammount = (Number(item.amountSettlement) || 0) * (this.detailObj.transType === 2 ? Number(item.purchasePrice) : Number(item.upstreamSettlementPrice) || 0)
          settleAmount += ammount
          this.$set(item, 'subtotalSettlement', roundUpToInteger(ammount))
        }
      })
      // 结算总数量
      this.detailObj.settleTotalWeight = roundUpToInteger(settleTotalWeight, 3)
      // 结算总价钱
      this.detailObj.settleAmount = roundUpToInteger(settleAmount)
      // 货款差额
      this.detailObj.differencePayment = roundUpToInteger(settleAmount - (Number(this.detailObj.paymentPaid) || 0))
      // 结算差额
      this.detailObj.settleDiff = roundUpToInteger(Number(this.detailObj.differencePayment) + (Number(this.detailObj.unpaidAgentCost) || 0) - (Number(this.detailObj.deductibleDepositAmount) || 0) + (this.detailObj.isOverdue === '1' ? Number(this.detailObj.unpaidOverdueCost || 0) : 0))
      // 结算结算总金额
      this.detailObj.totalSettlementAmount = roundUpToInteger(settleAmount + (Number(this.detailObj.unpaidAgentCost) || 0) + (Number(this.detailObj.agentCost) || 0) + (this.detailObj.isOverdue === '1' ? Number(this.detailObj.unpaidOverdueCost || 0) : 0) + (this.detailObj.isOverdue === '1' ? Number(this.detailObj.overdueCost || 0) : 0))
      // 计算完成之后把这个参数置为false
      this.isStatisticsComputation = false
      this.$forceUpdate()
    },
    // 查看垫付记录
    checkAdvanceRecord() {
      this.$refs.cushionCapitalRecord.visible = true
    },
    // 点击复选框的时候
    changeSelect(row) {
      if (!row.isSelected) {
        this.$set(row, 'upstreamSettlementPrice', '')
        this.$set(row, 'amountSettlement', '')
        this.$set(row, 'subtotalSettlement', '')
        this.statisticsComputation()
      }
    }
  }
}
</script>

<style lang="scss">
.additionalSettlementPageBox {
  .differential{
    padding: 30px 32px;
  }
  .cancelButton{
    margin-left: 32px;
  }
  .table {
    width: calc(100% - 64px);
    margin-left: 32px;
    margin-bottom: 32px;
    .intervalValue{
      input{
        border-color: red;
      }
    }
    .el-table__header .el-checkbox {
      display: none;
    }
  }
  .confirmationSettlement {
    margin: 0 32px;
    .el-descriptions-item__cell{
      padding-bottom: 24px;
    }
    .labelClassName{
      width: 132px;
      display: flex;
      align-items: center;
    }
    .el-input{
      width: 86%;
    }
    .icon {
      font-size: 14px;
      color: #666;
      margin-left: 12px;
    }
  }

  .el-descriptions {
    padding: 0 !important;
  }
  .settlementTitle {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
    span {
      color: #666;
      font-size: 14px;
      margin-left: 18px;
    }
  }
}
</style>
